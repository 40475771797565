define("discourse/plugins/discourse-ai/discourse/components/ai-tool-editor", ["exports", "@glimmer/component", "@ember/service", "discourse/components/back-button", "discourse/plugins/discourse-ai/discourse/components/ai-tool-editor-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _backButton, _aiToolEditorForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiToolEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get selectedPreset() {
      if (!this.args.selectedPreset) {
        return this.args.presets.findBy("preset_id", "empty_tool");
      }
      return this.args.presets.findBy("preset_id", this.args.selectedPreset);
    }
    get editingModel() {
      if (this.args.model.isNew) {
        return this.store.createRecord("ai-tool", this.selectedPreset);
      } else {
        return this.args.model;
      }
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <BackButton
          @route="adminPlugins.show.discourse-ai-tools"
          @label="discourse_ai.tools.back"
        />
    
        <AiToolEditorForm
          @model={{@model}}
          @tools={{@tools}}
          @editingModel={{this.editingModel}}
          @isNew={{@model.isNew}}
          @selectedPreset={{this.selectedPreset}}
        />
      
    */
    {
      "id": "+YeIg+yc",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.show.discourse-ai-tools\",\"discourse_ai.tools.back\"]],null],[1,\"\\n\\n    \"],[8,[32,1],null,[[\"@model\",\"@tools\",\"@editingModel\",\"@isNew\",\"@selectedPreset\"],[[30,1],[30,2],[30,0,[\"editingModel\"]],[30,1,[\"isNew\"]],[30,0,[\"selectedPreset\"]]]],null],[1,\"\\n  \"]],[\"@model\",\"@tools\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-tool-editor.js",
      "scope": () => [_backButton.default, _aiToolEditorForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiToolEditor;
});